<style scoped lang="less">
.main_cont {
  height: calc(100% - 40px);
  overflow-y: auto;
  width: 100%;
}
.checkBox {
  width: 95%;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 5px auto;
  .title {
    padding: 10px;
    text-indent: 5px;
    font-size: 15px;
    font-family: "blod";
    border-bottom: 1px solid #eeeeee;
  }
  .van-radio {
    border-bottom: 0 !important;
  }
  .button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.allSubmit {
  margin-top: 10px;
  height: 35px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "blod";
  font-size: 16px;
}
</style>
<template>
  <div class="main">
    <!-- <div  class="allSubmit" >
            <van-button type="primary" size="small" block @click="allSubmit()">关票</van-button>
            <van-button type="danger" size="small" block @click="cancelSubmit">整改</van-button>
        </div>
        <div class="main_cont"> -->
    <van-form style="width:95%;margin:10px auto;" @submit="onSubmit">
      <van-cell-group inset style="margin:0">
        <van-field label="操作" name="radio">
          <template #input>
            <van-radio-group v-model="query.action" direction="horizontal">
              <van-radio name="success" style="margin-right:20px"
                >关票</van-radio
              >
              <van-radio name="fail">整改</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="query.action == 'fail'"
          v-model="query.job_msg"
          type="textarea"
          autosize
          rows="1"
          label="整改原因"
          placeholder="整改原因"
          :rules="[{ required: true, message: '整改原因必填' }]"
        />
        <van-field
          v-if="query.action == 'fail'"
          v-model="query.rectification_plan_date"
          is-link
          readonly
          name="datetimePicker"
          label="整改时间"
          :rules="[{ required: true, message: '整改时间必填' }]"
          placeholder="点击选择时间"
          @click="showPicker = true"
        />
        <van-field
          name="uploader"
          label="现场照片"
          v-if="query.action == 'fail'"
        >
          <template #input>
            <van-icon
              name="chat-o"
              @click="showImg()"
              size="40"
              :badge="query.attachment2 ? query.attachment2.length : 0"
            />
          </template>
          <template #button>
            <van-button size="mini" type="primary" @click="takephoto()"
              >拍照上传</van-button
            >
            <van-button size="mini" type="primary" @click="checkphoto()"
              >相册上传</van-button
            >
          </template>
        </van-field>
      </van-cell-group>
      <div class="allSubmit">
        <van-button type="primary" size="small" block native-type="onSubmit"
          >提交</van-button
        >
      </div>
    </van-form>
    <!-- </div> -->
    <!-- 整改时间 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :min-date="new Date(endDate)"
      />
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted,reactive,ref,getCurrentInstance} from 'vue';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import {Notify,Dialog} from 'vant';
export default {
    setup() {
        const route = useRoute();
        const router = useRouter();
        const endDate = ref(route.params.end);
        const showPicker = ref(false)
        const query = reactive({
            method:'saveorupdate',
            t:'PTW_CETIFICATE',
            action:'success',
            id:route.params.id,
            job_msg:'',
            attachment2:[],
            rectification_plan_date:""
        })
        const onConfirm = (data) =>{
            query.rectification_plan_date = Http.momentDate(data,'YYYY-MM-DD HH:mm:ss');
            showPicker.value = false;
        }
        const allSubmit = () =>{
            Dialog.confirm({
                title: '标题',
                message: '确定需要进行关票操作 ？',
            })
            .then(() => {
                Http.get({
                    method:'saveorupdate',
                    t:'PTW_CETIFICATE',
                    action:'success',
                    id:route.params.id,
                },false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id)
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }

        

        const cancelSubmit = () =>{
            Dialog.confirm({
                title: '标题',
                message: '确定需要进行整改 ？',
            })
            .then(() => {
                Http.get(query,false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id)
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }
        const onSubmit = () =>{
            if(query.action == 'success'){
                allSubmit()
            }else{
                cancelSubmit()
            }
        }

        const imageurl = ref('');
        const onSuccess = (data) =>{
            imageurl.value = "data:image/png;base64," + data
            var file = Http.dataURLtoFile(imageurl.value);
            let formData = new FormData();
            formData.append('file', file, file.name);
            Http.fileuoload(formData)
            .then(res=>{
                query.attachment2.push({document_path:res})
                Http.loading.close()
            })
            .catch(()=>{
                alert(2)
                Http.loading.close()
            });
        }
        // 拍照
        const takephoto = () =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data)
                }, 
                function(err){
                    alert(err.toString())
                }, 
                {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,//返回FILE_URI类型
                    sourceType: Camera.PictureSourceType.CAMERA,//指定图片来自拍照
                    // cameraDirection: Camera.Direction.FRONT,//指定前后摄像头--好像没起作用
                    encodingType: Camera.EncodingType.PNG,
                    targetWidth: 150,
                    targetHeight: 150,
                    saveToPhotoAlbum:false
                });
        }
        // 从相册选择
        const checkphoto = () =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data)
                }, 
                function(err){
                    alert(err.toString())
                }, {
                quality: 50,
                destinationType: Camera.DestinationType.DATA_URL,//返回Base64编码字符串
                sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,//指定图片来自相册
                encodingType: Camera.EncodingType.PNG,//指定返回图片是png格式还是jpeg
                targetWidth: 150,
                targetHeight: 150
            });
        }
        const showImg = () =>{
            const baseconfig = Http.config();
            var images = [];
            var imgs = query.attachment2 || [];
            if(!imgs || imgs.length == 0){
                Notify({ type: 'warning', message: '暂无可查看图片' });
                return false;
            }else{
                imgs.forEach(elem =>{
                    images.push(baseconfig.baseUrl + elem.document_path);
                });
                ImagePreview({
                    images: images,
                    closeable:true,
                    startPosition: 1,
                    loop:true
                });
            }
        }
        return {
            query,
            allSubmit,
            cancelSubmit,
            showPicker,
            showImg,
            takephoto,
            checkphoto,
            onConfirm,
            endDate,
            onSubmit
        }
    },
}
</script>
